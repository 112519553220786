export default defineNuxtRouteMiddleware(async (to, from) => {
  if (process.server) return;

  const { initializeAppUserData, loadUserPermissions } = useAppUserData();
  await initializeAppUserData();

  await loadUserPermissions();

  const { initializeStudentsClassroomData } = useStudentClassroomsDataStore();
  await initializeStudentsClassroomData();
});
